.nav-row{
  background: transparent linear-gradient(90deg, #6CCDD9 0%, #247DBE 100%) 0% 0% no-repeat padding-box;
}
.nav-row > div{
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 25px;
  color: #FFFFFF;
}
.contact-title-row{
  justify-content: center;
  text-align: center;
  padding-top: 50px;
}
.contact-title-row > div > div {
  display: inline-block;
  width: auto;

}
.blue-text{
  color:#247DBE
}
.contact-content{
  font-weight: 400;
  color: #454545;
  text-align: center;
  padding-top: 30px;
  line-height: 17px;
}
.contact-container{
  box-shadow: 0px 3px 6px #00000029;
}
.btn-row{
  display: flex;
  justify-content: center;
  padding:10px;
}
.call-btn{
  background: transparent linear-gradient(90deg, #96E692 0%, #44E344 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 38px;
border: none;
font-size: 25px;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 25px;
  color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  padding:25px;
  max-width: 400px;
}
.call-btn-img{
  left:25px;
  position: absolute;
}
.title-text{
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 45px;
  color: #454545;
}
.title-text-sub{
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 45px;
  color: #454545;
  justify-content: center;
  text-align: center;
}
.company-container{
  background-color:#F4F4F4;
  text-align: center;
  padding-bottom: 30px;
}
.website-bar{
  background-color: white;
  margin-top: 20px;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  padding-top:15px;
  padding-bottom: 15px;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 800;
  align-items: center;
}
.website-bar > div{
  display: inline;
  padding-left: 10px;
  padding-right: 10px;
}
.company-container > div{
  display: flex;
  justify-content: center;
  
}
.website-bar-row{
  display: flex;
  justify-content: center;
}
.location-bar-row{
  display: flex;
  text-align: left;
}
.location-bar{
  background-color: white;
  margin-top: 20px;
  max-width: 400px;
  display: flex;
  padding-top:15px;
  padding-bottom: 15px;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 800;
}
.location-bar-sub{
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
}
.items-container * img {
  height: 80px;
  width: auto;
}
.items-container > div > div {
  display: flex;
  justify-content: center;
}
.items-container{
  padding-bottom: 40px;
}
.items-container > div{
  padding-top: 50px;
}
.item-title{
  padding-top: 20px;
  font-weight: 600;
  color: #454545;
}
.item-desc{
  text-align: center;
  
}
.item-desc > div {
  max-width: 400px;
  padding-top: 10px;
}
.text-block{
  display: flex;
  background-color: #247DBE;
  justify-content: center;
  padding-bottom: 40px;
}
.text-block > div {
  text-align: center;
  max-width: 400px;
  color:white;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.footer-container{
  background-color:#454545;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer-container > div{
  text-align: center;
  justify-content: center;
  padding-top: 10px;
}
.bold-font{
  font-weight: 700;
}

.contact-container > form{
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 1000px; 
}
.error-text{
  color:red;
}